const appConfig = {
  appProject: "vai",
  //production
  apiPrefix: "https://api.askred.ai",
  //development
  // apiPrefix: "https://devapi.askred.ai",
  apiDevPrefix: "https://devapi.askred.ai",

  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "",
  enableMock: false,
};

export default appConfig;
