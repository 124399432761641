// import { THEME_ENUM } from "constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
  iconNavColor: "#FAFAFA", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
  themeHexColor: "#FF1C00", // Value => HEX Code (Untuk global color dengan format hex code)
  themeColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
  tableColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
  direction: "ltr", // Value => ltr/rtl
  mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
  primaryColorLevel: 600, // Value => 50-900
  tableColorLevel: 300, // Value => 50-900
  cardBordered: true,
  panelExpand: false,
  controlSize: "md",
  navMode: "themed",
  layout: {
    type: "modern",
    sideNavCollapse: false,
  },
  locale: "id",
};

// Save Config VAI

// export const themeConfig = {
//   iconNavColor: "#FAFAFA", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
//   themeHexColor: "#FF1C00", // Value => HEX Code (Untuk global color dengan format hex code)
//   themeColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
//   tableColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
//   direction: "ltr", // Value => ltr/rtl
//   mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
//   primaryColorLevel: 600, // Value => 50-900
//   tableColorLevel: 300, // Value => 50-900
//   cardBordered: true,
//   panelExpand: false,
//   controlSize: "md",
//   navMode: "themed",
//   layout: {
//     type: "modern",
//     sideNavCollapse: false,
//   },
//   locale: "id",
// };

// END Save Config VAI

// Save Config MHIB

// export const themeConfig = {
//   iconNavColor: "#171717", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
//   themeHexColor: "#f59e0b", // Value => HEX Code (Untuk global color dengan format hex code)
//   themeColor: "amber", // Value => sesuai dengan tailwind e.g sky, red, dll
//   tableColor: "amber", // Value => sesuai dengan tailwind e.g sky, red, dll
//   direction: "ltr", // Value => ltr/rtl
//   mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
//   primaryColorLevel: 500, // Value => 50-900
//   tableColorLevel: 500, // Value => 50-900
//   cardBordered: true,
//   panelExpand: false,
//   controlSize: "md",
//   navMode: "themed-dark",
//   layout: {
//     type: "modern",
//     sideNavCollapse: false,
//   },
//   locale: "id",
// };

// END Save Config MHIB
